import { EditorAppSDK } from '@contentful/app-sdk';
import { useSDK } from '@contentful/react-apps-toolkit';
import { CollectionProp, EntryProps } from 'contentful-management';
import { LabelEntry } from '../types/labelEntry';

const PAGE_SIZE: number = 25;
const ENTRY_NAME: string = 'label';

export class LabelRepository {
  private sdk = useSDK<EditorAppSDK>();
  private cma = this.sdk.cma;

  public getAll = async (): Promise<LabelEntry[]> => {
    const contentEntries: LabelEntry[] = [];
    let skip: number = 0;

    let response: CollectionProp<EntryProps<any>>;

    const defaultLocale: string = this.sdk.locales.default;

    do {
      response = await this.cma.entry.getMany({
        query: {
          content_type: ENTRY_NAME,
          limit: PAGE_SIZE,
          skip: skip,
        },
      });

      skip += PAGE_SIZE;

      contentEntries.push(
        ...response.items
          .filter((entry) => {
            return (
              entry?.fields?.contentfulName &&
              entry?.fields?.contentfulName[defaultLocale] &&
              entry?.fields?.label &&
              entry?.fields?.label[defaultLocale] &&
              entry?.fields?.labelId &&
              entry?.fields?.labelId[defaultLocale]
            );
          })
          .map((entry) => {
            return {
              id: entry.fields.labelId[defaultLocale],
              title: entry.fields.contentfulName[defaultLocale],
            };
          })
      );
    } while (response.items.length);

    return contentEntries;
  };
}
