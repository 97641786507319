/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, FocusEvent, ChangeEvent } from 'react';
import {
  Box,
  FormControl,
  Grid,
  Spinner,
  Stack,
  Table,
  Text,
  TextInput,
} from '@contentful/f36-components';
import { useSDK } from '@contentful/react-apps-toolkit';
import { Notification } from '@contentful/f36-notification';
import {
  FilterConfiguration,
  filterConfigurationPositionCompareFn,
} from '../types/FilterConfiguration';
import FilterConfigurationRow from '../components/FilterConfigurationRow';
import FilterConfigurationAddRow from '../components/FilterConfigurationAddRow';
import {
  validateNewFilterConfiguration,
  validateFiltersConfiguration,
  validateExistingFilterConfiguration,
} from '../validations/FilterConfigurationValidation';
import { updateFiltersPositions } from '../manipulation/ArrayManipulation';
import {
  EmptyFilterConfigurationEntry,
  FilterConfigurationEntry,
  FilterConfigurationSnapshotEntry,
} from '../types/FilterConfigurationEntry';
import { LabelEntry } from '../types/labelEntry';
import { LabelRepository } from '../data/LabelsRepository';
import { ContentfulRepository } from '../data/ContentfulRepository';
import { sanitizeText } from '../manipulation/StringManipulation';
import {
  DEFAULT_FILTER_CONFIGURATION_ID,
  FilterConfigurationStatus,
} from '../components/FilterConfigurationPublishButton';
import { CollectionProp, EntryProps, SnapshotProps } from 'contentful-management/types';
import { EditorAppSDK } from '@contentful/app-sdk';

const useConstructor = (callBack = () => {}) => {
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  if (hasBeenCalled) return;
  callBack();
  setHasBeenCalled(true);
};

const Entry = () => {
  const sdk = useSDK<EditorAppSDK>();
  const cma = sdk.cma;

  const [labels, setLabels] = useState<LabelEntry[]>([]);
  const [ready, setReady] = useState<boolean>(false);
  const [idDisable, setIdDisable] = useState<boolean>(false);

  const [entryData, setEntryData] = useState<FilterConfigurationEntry>(
    EmptyFilterConfigurationEntry
  );

  const labelRepository = new LabelRepository();
  const contentfulRepository = new ContentfulRepository();

  useConstructor(() => {
    labelRepository.getAll().then((labels: LabelEntry[]) => {
      setLabels(labels);
      setReady(true);
    });

    const persistedContentfulName: string = contentfulRepository.getEntryField('contentfulName');
    const persistedFiltersConfigurationId: string =
      contentfulRepository.getEntryField('filterConfigurationId');
    let persistedFilterConfiguration: FilterConfiguration[] =
      contentfulRepository.getEntryField('configuration');

    if (!validateFiltersConfiguration(persistedFilterConfiguration)) {
      console.log('FilterConfiguration App found empty or invalid configuration. Recreating it.');
      persistedFilterConfiguration = [];
      sdk.entry.fields['configuration'].setValue(persistedFilterConfiguration);
    }

    setEntryData({
      contentfulName: persistedContentfulName,
      filterConfigurationId: persistedFiltersConfigurationId,
      configuration: persistedFilterConfiguration,
    });
  });

  const isProductionEnv = () => {
    return sdk.entry.getSys()?.environment?.sys?.id === 'master';
  };

  const getFilterConfigurationStatus = async (): Promise<FilterConfigurationStatus> => {
    let isDraft = true;
    let filterConfigurationId = contentfulRepository.getEntryField('filterConfigurationId');

    if (isProductionEnv()) {
      const snapshot = (await cma.snapshot.getManyForEntry<FilterConfigurationSnapshotEntry>({
        entryId: sdk.entry.getSys().id,
        snapshotId: '',
      })) as CollectionProp<SnapshotProps<EntryProps<FilterConfigurationSnapshotEntry>>>;

      isDraft = !snapshot || snapshot.items.length === 0;

      if (!isDraft) {
        filterConfigurationId =
          snapshot.items[0].snapshot.fields.filterConfigurationId[sdk.locales.default];
      }
    }

    return {
      isDraft: isDraft,
      isDefault: filterConfigurationId === DEFAULT_FILTER_CONFIGURATION_ID,
    };
  };

  useEffect(() => {
    const detachConfigurationValueChangeHandler = sdk.entry.fields['configuration'].onValueChanged(
      (newConfiguration: FilterConfiguration[]) => {
        if (JSON.stringify(entryData.configuration) !== JSON.stringify(newConfiguration)) {
          setEntryData((prevEntryData) => {
            return { ...prevEntryData, configuration: newConfiguration };
          });
        }
      }
    );

    const detachContentfulNameValueChangeHandler = sdk.entry.fields[
      'contentfulName'
    ].onValueChanged((newValue: string) => {
      if (entryData.contentfulName !== newValue) {
        setEntryData((prevEntryData) => {
          return { ...prevEntryData, contentfulName: newValue };
        });
      }
    });

    const detachFiltersConfigurationIdValueChangeHandler = sdk.entry.fields[
      'filterConfigurationId'
    ].onValueChanged((newValue: string) => {
      if (entryData.filterConfigurationId !== newValue) {
        setEntryData((prevEntryData) => {
          return { ...prevEntryData, filterConfigurationId: newValue };
        });
      }
    });

    getFilterConfigurationStatus().then((status: FilterConfigurationStatus) => {
      if (!status.isDraft && status.isDefault) {
        setIdDisable(true);
      }
    });

    return () => {
      if (detachConfigurationValueChangeHandler) {
        detachConfigurationValueChangeHandler();
      }
      if (detachContentfulNameValueChangeHandler) {
        detachContentfulNameValueChangeHandler();
      }
      if (detachFiltersConfigurationIdValueChangeHandler) {
        detachFiltersConfigurationIdValueChangeHandler();
      }
    };
  }, []);

  useEffect(() => {
    if (entryData.configuration) {
      contentfulRepository.setEntryField('configuration', entryData.configuration);
    }
  }, [entryData.configuration]);

  const getLabelById = (id: string): string => {
    const label = labels.find((l: LabelEntry) => l.id === id);

    if (!label) {
      return '';
    }

    return label.title;
  };

  const addFilterConfiguration = (addedFilterConfiguration: FilterConfiguration): boolean => {
    const validationResult = validateNewFilterConfiguration(
      addedFilterConfiguration,
      entryData.configuration
    );
    if (!validationResult.isValid) {
      Notification.error(validationResult.errorMessage, {
        id: 'addFilterConfiguration',
      });
      return false;
    }

    const lastPosition: number =
      entryData.configuration.length === 0
        ? 1
        : entryData.configuration[entryData.configuration.length - 1].position + 1;

    addedFilterConfiguration.position = lastPosition;

    setEntryData((prevEntryData) => {
      return {
        ...prevEntryData,
        configuration: [...prevEntryData.configuration, addedFilterConfiguration],
      };
    });

    return true;
  };

  const removeFilterConfiguration = (indexToRemove: number) => {
    setEntryData((prevEntryData) => {
      return {
        ...prevEntryData,
        configuration: prevEntryData.configuration.filter((_, _index) => _index !== indexToRemove),
      };
    });
  };

  const updateFilterConfiguration = (
    updatedFilterConfiguration: FilterConfiguration,
    index: number
  ): boolean => {
    const validationResult = validateExistingFilterConfiguration(updatedFilterConfiguration);

    if (!validationResult.isValid) {
      Notification.error(validationResult.errorMessage, {
        id: 'updateFilterConfiguration',
      });
      return false;
    }

    setEntryData((prevEntryData) => {
      return {
        ...prevEntryData,
        configuration: updateFiltersPositions(
          prevEntryData.configuration,
          updatedFilterConfiguration.position
        ).map((f: FilterConfiguration, i: number) =>
          i === index ? updatedFilterConfiguration : f
        ),
      };
    });

    return true;
  };

  const contentfulNameChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setEntryData((prevEntryData) => {
      return { ...prevEntryData, contentfulName: event.target.value };
    });
  };

  const contentfulNameBlurHandler = (event: FocusEvent<HTMLInputElement>) => {
    contentfulRepository.setEntryField('contentfulName', event.target.value);
  };

  const contentfulIdChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setEntryData((prevEntryData) => {
      return {
        ...prevEntryData,
        filterConfigurationId: sanitizeText(event.target.value),
      };
    });
  };

  const contentfulNameIdHandler = (event: FocusEvent<HTMLInputElement>) => {
    contentfulRepository.setEntryField('filterConfigurationId', sanitizeText(event.target.value));
  };

  return (
    <Box padding="spacingL">
      <Stack flexDirection="column" spacing="spacingS" alignItems="initial">
        {ready === false ? (
          <Stack flexDirection="column" spacing="spacingS" alignItems="center">
            <Text marginRight="spacingXs" fontSize="fontSizeL">
              Loading filter configuration
            </Text>
            <Spinner variant="default" size="large" />
          </Stack>
        ) : (
          <>
            <Grid>
              <FormControl isRequired>
                <FormControl.Label>Contentful name (required)</FormControl.Label>
                <TextInput
                  value={entryData.contentfulName}
                  onChange={contentfulNameChangeHandler}
                  onBlur={contentfulNameBlurHandler}
                />
                <FormControl.HelpText>Maximum 256 characters</FormControl.HelpText>
              </FormControl>

              <FormControl isRequired>
                <FormControl.Label>filterConfigurationId (required)</FormControl.Label>
                <TextInput
                  isDisabled={idDisable}
                  value={entryData.filterConfigurationId}
                  onChange={contentfulIdChangeHandler}
                  onBlur={contentfulNameIdHandler}
                />
                <FormControl.HelpText>Maximum 256 characters</FormControl.HelpText>
              </FormControl>
            </Grid>
            <Table>
              <Table.Head>
                <Table.Row>
                  <Table.Cell>Filter List</Table.Cell>
                  <Table.Cell>Position</Table.Cell>
                  <Table.Cell>Show More Limit</Table.Cell>
                  <Table.Cell>Properties</Table.Cell>
                  <Table.Cell></Table.Cell>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                <>
                  {entryData?.configuration
                    .sort(filterConfigurationPositionCompareFn)
                    .map((filter: FilterConfiguration, index: number) => {
                      return (
                        <FilterConfigurationRow
                          key={`${filter.labelId}-${filter.position}`}
                          filterConfigurationTitle={getLabelById(filter.labelId)}
                          filterConfiguration={filter}
                          onFilterConfigurationRemoved={() => removeFilterConfiguration(index)}
                          onFilterConfigurationChanged={(
                            filterConfiguration: FilterConfiguration
                          ) => {
                            return updateFilterConfiguration(filterConfiguration, index);
                          }}
                        />
                      );
                    })}
                </>
                <FilterConfigurationAddRow
                  onFilterConfigurationAdded={addFilterConfiguration}></FilterConfigurationAddRow>
              </Table.Body>
            </Table>
          </>
        )}
      </Stack>
    </Box>
  );
};

export default Entry;
