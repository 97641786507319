import { FilterConfiguration } from '../types/FilterConfiguration';
import {
  FilterConfigurationValidationResult,
  buildValidationResult,
} from '../types/FilterConfigurationValidationResult';

export const validateNewFilterConfiguration = (
  addedFilterConfiguration: FilterConfiguration,
  filtersConfiguration: FilterConfiguration[]
): FilterConfigurationValidationResult => {
  if (
    filtersConfiguration.find(
      (p: FilterConfiguration, i: number) => p.labelId === addedFilterConfiguration.labelId
    )
  ) {
    return buildValidationResult(false, 'The filter you are trying to add already exists.');
  }

  return buildValidationResult(true, '');
};

export const validateExistingFilterConfiguration = (
  filterConfiguration: FilterConfiguration
): FilterConfigurationValidationResult => {
  if (filterConfiguration.position <= 0) {
    return buildValidationResult(false, 'The filter position must be a number greater than 0');
  }

  if (filterConfiguration.showMoreLimit <= 0) {
    return buildValidationResult(false, 'The limit must be a number greater than 0');
  }

  return buildValidationResult(true, '');
};

export const validateFiltersConfiguration = (configuration: any): boolean => {
  try {
    if (!configuration) {
      return false;
    }

    if (
      !configuration.every((filter: FilterConfiguration) => {
        return (
          validateLabelEntry(filter) &&
          validateNumericProperty('position', filter) &&
          validateNumericProperty('showMoreLimit', filter) &&
          validateVisibility(filter)
        );
      })
    ) {
      return false;
    }

    return true;
  } catch {
    return false;
  }
};

const validateLabelEntry = (filter: any): boolean => {
  return 'labelId' in filter && typeof filter.labelId === 'string' && filter.labelId !== '';
};

const validateNumericProperty = (property: keyof FilterConfiguration, filter: any): boolean => {
  return property in filter && typeof filter[property] === 'number' && filter.position > 0;
};

const validateVisibility = (filter: any): boolean => {
  return 'visibility' in filter && ['visible', 'hidden', 'pinned'].includes(filter.visibility);
};
