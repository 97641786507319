import { EditorAppSDK } from '@contentful/app-sdk';
import { useSDK } from '@contentful/react-apps-toolkit';

export class ContentfulRepository {
  private sdk = useSDK<EditorAppSDK>();

  public getEntryField = (property: string): any => {
    return this.sdk.entry.fields[property].getValue();
  };

  public setEntryField = (fieldName: string, value: any) => {
    const storedValue = this.sdk.entry.fields[fieldName].getValue();

    if (JSON.stringify(storedValue) !== JSON.stringify(value)) {
      this.sdk.entry.fields[fieldName].setValue(value);
    }
  };
}
