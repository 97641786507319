import { FilterConfiguration } from './FilterConfiguration';

export type FilterConfigurationEntry = {
  contentfulName: string;
  filterConfigurationId: string;
  configuration: FilterConfiguration[];
};

export type FilterConfigurationSnapshotEntry = {
  contentfulName: string;
  filterConfigurationId: {
    [locale: string]: string
  };
  configuration: FilterConfiguration[];
};

export const EmptyFilterConfigurationEntry = {
  contentfulName: '',
  filterConfigurationId: '',
  configuration: [],
};
