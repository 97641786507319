/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { ContentEntitySys, SidebarAppSDK } from '@contentful/app-sdk';
import { useSDK } from '@contentful/react-apps-toolkit';
import { Button } from '@contentful/f36-button';
import { Badge, BadgeVariant } from '@contentful/f36-badge';
import { Box, Flex, RelativeDateTime, Spinner, Stack } from '@contentful/f36-components';
import { Text } from '@contentful/f36-typography';
import { ContentfulRepository } from '../data/ContentfulRepository';
import { Notification } from '@contentful/f36-notification';
import { CollectionProp, SnapshotProps, EntryProps } from 'contentful-management';
import { FilterConfigurationSnapshotEntry } from '../types/FilterConfigurationEntry';

type EntryStatus = 'DRAFT' | 'PUBLISHED' | 'CHANGED';

export const DEFAULT_FILTER_CONFIGURATION_ID = 'defaultFilterConfigurationId';

export interface FilterConfigurationStatus {
  isDraft: boolean;
  isDefault: boolean;
}

const FilterConfigurationPublishButton = (): JSX.Element => {
  const sdk = useSDK<SidebarAppSDK>();
  const cma = sdk.cma;

  const [entryEntitySys, setEntryEntitySys] = useState<ContentEntitySys>(sdk.entry.getSys());
  const [saving, setSaving] = useState<boolean>(false);

  const contentfulRepository = new ContentfulRepository();

  useEffect(() => {
    sdk.entry.fields['contentfulName'].onValueChanged(startSaving);
    sdk.entry.fields['filterConfigurationId'].onValueChanged(startSaving);
    sdk.entry.fields['configuration'].onValueChanged(startSaving);

    sdk.entry.onSysChanged((sys: ContentEntitySys) => {
      setEntryEntitySys(sys);
      setSaving(false);
    });
  }, []);

  const startSaving = () => {
    setSaving(true);
  };

  const isProductionEnv = () => {
    return entryEntitySys.environment.sys.id === 'master';
  };

  const getFilterConfigurationStatus = async (): Promise<FilterConfigurationStatus> => {
    let isDraft = true;
    let filterConfigurationId = contentfulRepository.getEntryField('filterConfigurationId');

    if (isProductionEnv()) {
      const snapshot = (await cma.snapshot.getManyForEntry<FilterConfigurationSnapshotEntry>({
        entryId: entryEntitySys.id,
        snapshotId: '',
      })) as CollectionProp<SnapshotProps<EntryProps<FilterConfigurationSnapshotEntry>>>;

      isDraft = !snapshot || snapshot.items.length === 0;

      if (!isDraft) {
        filterConfigurationId =
          snapshot.items[0].snapshot.fields.filterConfigurationId[sdk.locales.default];
      }
    }

    return {
      isDraft: isDraft,
      isDefault: filterConfigurationId === DEFAULT_FILTER_CONFIGURATION_ID,
    };
  };

  const validateFilterConfiguration = async (): Promise<boolean> => {
    const { isDraft, isDefault } = await getFilterConfigurationStatus();

    if (isDefault && !isDraft) {
      const filterConfigurationId: string =
        contentfulRepository.getEntryField('filterConfigurationId');

      if (filterConfigurationId !== DEFAULT_FILTER_CONFIGURATION_ID) {
        Notification.error('ID of the default filter configuration cannot be changed', {
          id: 'FilterConfigurationAppNotificationId',
        });
        return false;
      }
    }

    return true;
  };

  const buttonClick = async (status: EntryStatus) => {
    switch (status) {
      case 'DRAFT':
      case 'CHANGED':
        if (await validateFilterConfiguration()) {
          await sdk.entry.publish();
        }
        break;
      case 'PUBLISHED':
        if ((await getFilterConfigurationStatus()).isDefault) {
          Notification.error('Default filter configuration cannot be unpublished', {
            id: 'FilterConfigurationAppNotificationId',
          });
        } else {
          await sdk.entry.unpublish();
        }
        break;
    }
  };

  const renderButton = (status: EntryStatus): JSX.Element => {
    return (
      <Button variant="positive" isFullWidth={true} onClick={() => buttonClick(status)}>
        {
          {
            DRAFT: 'Publish',
            PUBLISHED: 'Unpublish',
            CHANGED: 'Publish changes',
          }[status]
        }
      </Button>
    );
  };

  const renderStatus = (status: EntryStatus): JSX.Element => {
    let variant: BadgeVariant = 'warning';
    switch (status) {
      case 'PUBLISHED':
        variant = 'positive';
        break;
      case 'CHANGED':
        variant = 'primary';
        break;
    }

    return <Badge variant={variant}>{status.toString()}</Badge>;
  };

  const getEntryStatus = (): EntryStatus => {
    if (saving) {
      return 'CHANGED';
    }

    if (entryEntitySys && entryEntitySys.publishedVersion) {
      return entryEntitySys.publishedAt === entryEntitySys.updatedAt ? 'PUBLISHED' : 'CHANGED';
    }

    return 'DRAFT';
  };

  const entryStatus: EntryStatus = getEntryStatus();

  return entryEntitySys ? (
    <Stack flexDirection="column" spacing="spacingS">
      <Flex fullWidth={true} flexBasis={0} justifyContent="space-between">
        <Box>Current</Box>
        {renderStatus(entryStatus)}
      </Flex>
      <Flex fullWidth={true}>{renderButton(entryStatus)}</Flex>
      <Stack flexDirection="row" spacing="spacing2Xs" alignContent="flex-start" fullWidth={true}>
        {saving ? (
          <>
            <Text>Saving</Text>
            <Spinner variant="default" size="medium" />
          </>
        ) : (
          <>
            <Text>Last saved</Text>
            <RelativeDateTime prefix="Last saved" date={entryEntitySys.updatedAt} />
          </>
        )}
      </Stack>
    </Stack>
  ) : (
    <>
      <Text>Loading</Text>
      <Spinner variant="default" size="medium" />
    </>
  );
};

export default FilterConfigurationPublishButton;
